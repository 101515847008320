import React from "react";

const Landing = () => {
  return (
    <div className="bg-neutral-900 h-full flex flex-col lg:relative ">
      <div className="flex-grow flex flex-col">
        <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 pt-10 sm:pt-16 ">
            <a href="/" className="inline-flex">
              <span className="sr-only">Scott Energy</span>
              <img
                className="h-32 w-auto"
                src={process.env.PUBLIC_URL + "/logo.svg"}
                alt=""
              />
            </a>
          </div>
          <div className="flex-shrink-0 my-auto py-16 sm:py-32 ">
            <h1 className="mt-2 text-4xl font-bold text-amber-400 tracking-tight sm:text-5xl">
              Scott Energy
            </h1>
            <p className="mt-2 text-base text-gray-300">
              Distributed energy for a distributed world.
            </p>
            <div className="mt-6">
              <a
                href="mailto:questions@scott.energy"
                className="text-base font-medium text-sky-400 hover:text-indigo-500"
              >
                Got questions?<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://www.nasa.gov/sites/default/files/thumbnails/image/us_lights2.jpg"
          alt=""
        />
      </div>
      <footer className="bg-neutral-900">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-300">
              {`© ${new Date().getFullYear()} Scott Energy. All rights reserved.`}
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const App = () => {
  return (
    // <div className="bg-neutral-900 container mx-auto px-4 sm:px-6 lg:px-8 h-full">
    <Landing />
    // </div>
  );
};

export default App;
